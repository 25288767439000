<template>
    <div>
        <!-- <card-header :header="headers"></card-header> -->

        <v-tabs v-model="tabe" hide-slider v-if="navadress === 'dashc'" class="nav-dash-tab elevation-0" content-class="elevation-0" flat>
            <v-tab v-for="(tab, index) in tabsc" :key="index" :to="tab.link" elevation="0" class="nav-dash-tab-item"
                active-class='nav-dash-tab-item-active'>
                {{ tab.name }}
            </v-tab>
        </v-tabs>

        <!-- <v-tabs v-model="tabe" hide-slider elevation="0" v-if="navadress === 'dashu'" class="nav-dash-tab"> -->
        <v-tabs v-model="tabe" hide-slider v-if="navadress === 'dashu'" class="nav-dash-tab elevation-0" elevation="0" content-class="elevation-0" flat>
            <v-tab v-for="(tab, index) in tabsu" :key="index" :to="tab.link" elevation="0" class="nav-dash-tab-item"
                active-class='nav-dash-tab-item-active'>
                {{ tab.name }}
            </v-tab>
        </v-tabs>
        <!-- </v-card> -->
        <div class="mt-4">
            <router-view class="rounded-lg">
            </router-view>
            <!-- <v-tabs-items v-model="tabe">
            <v-tab-item :transition="false">
                <inbox :owner="navadress"></inbox>
            </v-tab-item>

            <v-tab-item :transition="false">
                <outbox :owner="navadress"></outbox>
            </v-tab-item>

            <v-tab-item :transition="false">
                <new-r-f-c></new-r-f-c>
            </v-tab-item>
        </v-tabs-items> -->
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import Inbox from './rfc/Inbox.vue'
import Outbox from './rfc/Outbox.vue'
import NewRFC from './rfc/NewRFC.vue'
import { mdiShapeRectanglePlus, mdiKeyboardBackspace } from '@mdi/js'
import CardHeader from '@/views/dashboard/components/CardHeader.vue'

export default {
    components: {
        Inbox,
        Outbox,
        NewRFC,
        CardHeader
    },
    data() {
        return {
            headers: {
                titlepage: 'REQUESTS FOR COLABORATIONS',
                icon: null,
            },
            loading_rfp_list: true,
            myrfpdata: [],
            isActionnedNewRfc: false,
            tabe: '',
            tabsc: [
                { name: "Received RFC", link: { name: 'company.rfc.inbox' } },
                { name: "Sent RFC", link: { name: 'company.rfc.outbox' } },
                // { name: "Sent RFC", link: { name: 'company.rfc.outbox' } },
                // { name: "Sent RFC", link: { name: 'company.rfc.outbox', params: {company: this.$store.getters.getCompany.name}} },
                { name: "Create RFC", link: { name: 'company.rfc.new' } },
            ],
            tabsu: [
                { name: "Received RFC", link: { name: 'user.rfc.inbox' } },
                { name: "Sent RFC", link: { name: 'user.rfc.outbox' } },
                // { name: "Create RFC", link: { name: 'user.rfc.new' } },
            ],
            navadress: ((this.$route.path).substr(0, "/dashc".length).toUpperCase() === "/dashc".toUpperCase()) ? 'dashc' : 'dashu',
        }
    },
    watch: {
        $route: {
            handler: function (newRouteValue) {
                let company = "/dashc"
                let professional = "/dashu"
                if ((this.$route.path).substr(0, company.length).toUpperCase() === company.toUpperCase()) {
                    this.navadress = 'dashc'
                }
                if ((this.$route.path).substr(0, professional.length).toUpperCase() === "/dashu".toUpperCase()) {
                    this.navadress = 'dashu'
                }
                if ((this.$route.path).substr(this.$route.path.length - 3, this.$route.path.length).toLowerCase() === 'new') {
                    this.isActionnedNewRfc = true
                }
                else {
                    this.isActionnedNewRfc = false
                }
            }
        }
    },
    beforeMount() {
        this.checkRouterNew()
    },
    methods: {
        checkRouterNew() {
            if ((this.$route.path).substr(this.$route.path.length - 3, this.$route.path.length).toLowerCase() === 'new') {
                this.isActionnedNewRfc = true
            }
            else {
                this.isActionnedNewRfc = false
            }
        },
        createNewRequest() {
            this.isActionnedNewRfc = true
            if (this.navadress === 'dashc') {
                this.$router.push({ name: 'company.rfc.new', params: { company: this.$store.getters.getCompany.name } })
            }
            else {
                this.$router.push({ name: 'user.rfc.new' })
            }
        }
    },
    beforeUpdate() {
        // this.$router.push({name: 'inbox' });
    },
    beforeCreate() {
        // if((this.$route.path).substr(1, "/dashu".length-1) === "dashu"){this.$router.push({name: 'user.rfc.inbox' });}
        // if((this.$route.path).substr(1, "/dashc".length-1) === "dashc"){this.$router.push({name: 'company.rfc.inbox' });}
    },

    setup() {
        const icons = { mdiShapeRectanglePlus, mdiKeyboardBackspace }
        return {
            icons
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/styles/tabs.scss';


.avatar-center {
    top: -50%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}
</style>
